<template>
  <div class="add-skill-container">
    <div class="dialog-exit-bar" :style="{'color': colors.PRIMARY}">
      <v-btn
        v-on:click="$emit('closeDialog')"
        class="exit-dialog-button"
        :color="colors.PRIMARY"
        fab
        absolute
        left
      >
        <v-icon :color="'white'">close</v-icon>
      </v-btn>
    </div>
    <div class="title-bar">
      <h1 v-if="isNew">Create a New Skill</h1>
      <h1 v-else>Edit Skill</h1>
    </div>
    <v-form class="skill-inputs-container" ref="inputform">
      <v-text-field
        class="padded-input"
        :color="colors.PRIMARY"
        :label="'Skill Name'"
        v-model="tmpModel.name"
        :rules="[v => !!v || 'Skill Name is required.']"
      ></v-text-field>
      <v-autocomplete
        class="padded-input"
        :no-data-text="'No categories found'"
        :color="colors.PRIMARY"
        :label="'Category'"
        :items="tagOptions"
        :item-text="'name'"
        v-model="selectedCategory"
        v-on:change="selectCategory()"
        :rules="[v => !!v || 'Category is required.']"
      ></v-autocomplete>
      <v-autocomplete
        class="padded-input"
        :color="colors.PRIMARY"
        :label="'Additional Tags'"
        :items="tagOptions"
        :item-text="'name'"
        :item-tmpModel="'id'"
        chips
        clearable
        multiple
        solo
        v-on:change="selectTags()"
        v-model="selectedTags"
      >
        <template v-slot:selection="data">
          <v-chip class="white--text" :input-value="data.selected" :color="colors.PRIMARY">
            <strong>{{data.item.name}}</strong>
          </v-chip>
        </template>
      </v-autocomplete>
      <ProficiencySlider
        class="slider-container"
        v-if="canCreateProficiency"
        :proficiency="proficiency"
        :saveOnChange="false"
        :canEdit="true"
      />
      <v-btn
        v-if="isNew && !isSaving"
        class="white--text create-button"
        :color="colors.PRIMARY"
        v-on:click="createSkill"
      >Create</v-btn>
      <v-btn
        v-else-if="!isSaving"
        class="white--text create-button"
        :color="colors.PRIMARY"
        v-on:click="updateSkill"
      >Save</v-btn>
      <v-progress-circular
        class="loading-indicator"
        v-if="isSaving"
        indeterminate
        :color="colors.PRIMARY"
      ></v-progress-circular>
    </v-form>
  </div>
</template>

<script>
import colors from "@/colors";
import _ from "lodash";
import ProficiencySlider from "@/views/Skills/components/ProficiencySlider";
import skillDataAccess from '../../../dataAccess/skillDataAccess';
import tagDataAccess from '../../../dataAccess/tagDataAccess';
import proficiencyDataAccess from '../../../dataAccess/proficiencyDataAccess';
import userDataAccess from '../../../dataAccess/userDataAccess';

export default {
  name: "editskill",
  components: {
    ProficiencySlider
  },
  data() {
    return {
      colors,
      skillName: null,
      selectedCategory: null,
      tmpModel: {
        category: {}
      },
      isSaving: false,
      tagOptions: [],
      selectedTags: [],
      skillDAO: new skillDataAccess(),
      tagDAO: new tagDataAccess(),
      proficiencyDAO: new proficiencyDataAccess(),
      userDAO: new userDataAccess(),
      proficiency: {
        id: 0,
        skillName: "",
        level: 1,
        email: ""
      }
    };
  },
  props: ["value", "isNew", "canCreateProficiency", "userEmail"],
  computed: {
    formIsValid() {
      return this.$refs.inputform.validate();
    }
  },
  methods: {
    selectCategory() {
      this.tmpModel.category = _.find(
        this.tagOptions,
        tag => tag.name === this.selectedCategory
      );
    },
    selectTags(){
      this.tmpModel.tags = this.tagOptions.filter((tag) => {
        return this.selectedTags.includes(tag.name);
      });
    },
    async createSkill() {
      if (!this.formIsValid) {
        return;
      }

      this.isSaving = true;

      let response = "";
      try {
        response = await this.skillDAO.postSkill(this.tmpModel);
      } catch (err) {
        console.log(err);
        this.$emit("saveError", err.response.data);
        this.isSaving = false;
        return;
      }

      var didCreateProficiency = await this.createProficiency();
      if(!didCreateProficiency){
        return;
      }

      this.value.id = response.id;
      this.value.name = response.name;
      this.value.category = response.category;
      this.value.tags = response.tags;

      this.$emit("closeDialog");
      this.$emit("createSkillComplete");
      this.isSaving = false;
    },
    async createProficiency() {
      this.proficiency.email = this.userEmail;
      this.proficiency.skillName = this.tmpModel.name;

      try{
        await this.proficiencyDAO.postProficiency(this.proficiency);
      }
      catch(err){
        this.$emit("saveError", err.response.data);
        this.isSaving = false;
        return false;
      }

      return true;
    },
    async updateSkill() {
      if (!this.formIsValid) {
        return;
      }

      this.isSaving = true;

      let response = "";
      try {
        response = await this.skillDAO.putSkill(this.tmpModel);
      } catch (err) {
        this.$emit("saveError", err.response.data);
        this.isSaving = false;
        return;
      }

      this.value.id = response.id;
      this.value.name = response.name;
      this.value.category = response.category;
      this.value.tags = response.tags;

      this.$emit("closeDialog");
      this.isSaving = false;
    },
    initForNewModel() {
      this.tmpModel = Object.assign({}, this.value);

      this.proficiency.id = 0;
      this.proficiency.skillName = "";
      this.proficiency.level = 1;
      this.proficiency.email = "";
      
      this.selectedCategory = this.tmpModel.category.name;
      this.selectedTags = this.tmpModel.tags.map((tag) => { return tag.name });
      this.$refs.inputform.resetValidation();
    },
    async initTagOptions(){
      this.tagOptions = await this.tagDAO.getTags();
      this.tagOptions.sort((tag1, tag2) => {
        var tag1Name = tag1.name.toUpperCase();
        var tag2Name = tag2.name.toUpperCase();
        return (tag1Name < tag2Name) ? -1 : (tag1Name > tag2Name) ? 1 : 0;
      });
    }
  },
  mounted: async function() {
    this.initForNewModel();
    await this.initTagOptions();
  },
  watch: {
    value: {
      handler: function() {
        this.initForNewModel();
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.add-skill-container {
  max-width: 600px;
  box-shadow: 0px 1px 5px #aaaaaa;
  background-color: #fefefe;
}

.dialog-exit-bar {
  height: 80px;
}

.exit-dialog-button {
  margin-top: 10px;
}

.skill-inputs-container {
  margin: 20px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.padded-input {
  margin: 10px 0;
}

.create-button {
  margin-bottom: 25px;
  margin-top: -10px;
}

.transparent-element {
  background-color: transparent !important;
}

.loading-indicator {
  margin-top: -10px;
  margin-bottom: 29px;
}

.slider-container {
  margin-top: -30px;
  margin-bottom: 15px;
}

@media screen and (max-width: 900px) {
  .add-skill-container {
    width: 100%;
    height: 100%;
    max-width: inherit;
  }
}

@media screen and (min-width: 900px) {
  .dialog-exit-bar {
    display: none;
  }
}
</style>