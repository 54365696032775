import apiWrapper from "../apiWrapper";
import store from "../store";

class proficiencyDataAccess {
    static cacheTimeoutMilliseconds = 1000 * 60 * 5;
    static lastGetProficienciesUpdate = -1;
    static lastEmailRetrieved = ""; 
    static lastGetAllProficienciesUpdate = -1;

    async getProficiencies(email){
        if(this.needsGetProficienciesUpdate(email)){
            let response = await apiWrapper.getProficiencies(email);
            store.commit("updateProficiencyList", response.data);

            proficiencyDataAccess.lastEmailRetrieved = email;
            proficiencyDataAccess.lastGetProficienciesUpdate = new Date().getTime();
        }
        
        return store.getters.proficiencyList;
    }

    async getProficienciesBySkills(skillIds){
        let response = await apiWrapper.getProficienciesBySkills(skillIds);
        store.commit("updateProficienciesBySkill", response.data);

        return store.getters.proficienciesBySkill;
    }

    async getAllProficiencies(userEmail){
        if(this.needsGetAllProficienciesUpdate()){
            let response = await apiWrapper.getAllProficiencies();
            store.commit("updateAllProficienciesList", response.data);
            store.commit("updateProficiencyList", response.data.filter(proficiency => {
                return proficiency.email === userEmail
            }));

            proficiencyDataAccess.lastGetAllProficienciesUpdate = new Date().getTime();
            proficiencyDataAccess.lastGetProficienciesUpdate = proficiencyDataAccess.lastGetAllProficienciesUpdate;
            proficiencyDataAccess.lastEmailRetrieved = userEmail;
        }

        return store.getters.allProficienciesList;
    }

    async postProficiency(proficiency){
        let response = await apiWrapper.postProficiency(proficiency);
        store.commit("addProficiency", response.data);
        return store.getters.proficiency(response.data);
    }

    async putProficiency(proficiency){
        let response = await apiWrapper.putProficiency(proficiency);
        store.commit("updateProficiency", response.data);
        return store.getters.proficiency(response.data);
    }

    async patchProficiencyReviewDate(proficiency){
        await apiWrapper.patchProficiencyReviewDate(proficiency);
        store.commit("updateProficiencyReviewDate", proficiency);
        return store.getters.proficiency(proficiency);
    }

    async deleteProficiency(proficiency){
        await apiWrapper.deleteProficiency(proficiency);
        store.commit("deleteProficiency", proficiency);
    }

    needsGetProficienciesUpdate(email){
        return proficiencyDataAccess.lastEmailRetrieved !== email ||
               proficiencyDataAccess.lastGetProficienciesUpdate === -1 ||
               new Date().getTime() - proficiencyDataAccess.lastGetProficienciesUpdate >= proficiencyDataAccess.cacheTimeoutMilliseconds;
    }

    needsGetAllProficienciesUpdate(){
        return new Date().getTime() - proficiencyDataAccess.lastGetAllProficienciesUpdate >= proficiencyDataAccess.cacheTimeoutMilliseconds
    }
}

export default proficiencyDataAccess;