<template>
  <div class="skill-list-container">
    <v-card class="title-bar search-title-bar">
      <h1 class="search-title">Skills</h1>
      
      <div class="search-input-wrapper">
        <v-autocomplete
          class="search-input"
          :placeholder="'Search'"
          :no-data-text="'No skills found'"
          :color="colors.PRIMARY"
          :items="skillList"
          :item-text="'name'"
          :loading="isLoadingSkills"
          v-model="searchSelection"
          v-on:change="displaySearchedSkill()"
        ></v-autocomplete>
      </div>

      <v-btn v-on:click="displayNewSkill()" :color="colors.PRIMARY" absolute bottom right fab>
        <v-icon :color="'white'">add</v-icon>
      </v-btn>
    </v-card>

    <div margin-top="100px">
      <v-list class="skills-list">
        <v-list-item
          class="padded-tile"
          v-for="skill in skillList"
          :key="skill.Id"
          @click="displaySkill(skill)"
        >       
          
            <v-list-item-title class="text-left">{{skill.name}}</v-list-item-title>
            <v-list-item-subtitle>{{skill.category.name}}</v-list-item-subtitle>
            <v-icon :color="colors.PRIMARY">edit</v-icon>
        </v-list-item>
      </v-list>
    </div>
    <v-dialog
      v-model="addSkillShown"
      lazy
      :transition="isMobileDialog ? 'dialog-bottom-transition' : ''"
      :fullscreen="isMobileDialog"
      max-width="600px"
    >
      <EditSkill
        v-model="currentSelectedSkill"
        :isNew="dialogIsNewSkill"
        v-on:closeDialog="addSkillShown = false"
        v-on:saveError="displayError"
      />
    </v-dialog>
    <v-snackbar v-model="snackbarShown" :timeout="6000" :color="'red'">{{snackbarMessage}}</v-snackbar>
  </div>
</template>

<script>
import colors from "@/colors";
import EditSkill from "./EditSkill.vue";
import _ from "lodash";
import skillDataAccess from "@/dataAccess/skillDataAccess";
import tagDataAccess from "@/dataAccess/tagDataAccess";

export default {
  name: "skilllist",
  components: {
    EditSkill
  },
  data() {
    return {
      colors,
      addSkillShown: false,
      isLoadingSkills: true,
      currentSelectedSkill: {
        id: 0,
        name: "",
        category: {
          id: 0,
          name: ""
        },
        tags: []
      },
      searchSelection: "",
      dialogIsNewSkill: false,
      snackbarShown: false,
      snackbarMessage: "",
      skillList: [],
      skillDAO: new skillDataAccess(), 
      tagDAO: new tagDataAccess()
    };
  },
  props: ["tagList"],
  computed: {
    isMobileDialog() {
      return window.innerWidth < 900;
    }
  },
  mounted: async function(){
    await this.initSkillList();
    this.isLoadingSkills = false;
  },
  methods: {
    async initSkillList() {
      try {
        this.skillList = await this.skillDAO.getAllSkills();
        this.isSkillListLoading = false;
      } catch (err) {
        console.log(err);
        return;
      }

    },
    displayNewSkill() {
      this.dialogIsNewSkill = true;
      this.currentSelectedSkill = {
        id: 0,
        name: "",
        category: {
          id: 0,
          name: ""
        },
        tags: []
      };
      this.addSkillShown = true;
    },
    displaySearchedSkill() {
      this.currentSelectedSkill = _.find(
        this.skillList,
        skill => skill.name === this.searchSelection
      );
      this.dialogIsNewSkill = false;
      this.addSkillShown = true;
    },
    displaySkill(skill) {
      this.dialogIsNewSkill = false;
      this.currentSelectedSkill = skill;
      this.addSkillShown = true;
    },
    displayError(errorMessage) {
      this.snackbarMessage = "Error Saving: " + errorMessage;
      this.snackbarShown = true;
    }
  }
};
</script>

<style scoped>
  .skill-list-container {
    max-width: 600px;
    margin: 20px;
    box-shadow: 0px 1px 5px #aaaaaa;
    background-color: #fefefe;
  }

  .search-input-wrapper {
    margin-right: 100px;
    margin-left: 25px;
    width: 100%;
  }

  .search-title {
    margin-top: 12px;
  }

  .search-title-bar {
    display: flex;
    flex-direction: row;
    align-content: center;
    padding-top: 10px;
    padding-bottom: 5px;
    z-index: 0;
  }

  .skills-list {
    background-color: transparent !important;
    margin-top: 25px;
  }

  .padded-tile {
    padding: 2px 15px;
  }
</style>
