<template>
  <div class="add-tag-container">
    <div class="dialog-exit-bar" :style="{'color': colors.PRIMARY}">
      <v-btn
        v-on:click="$emit('closeDialog')"
        class="exit-dialog-button"
        :color="colors.PRIMARY"
        fab
        absolute
        left
      >
        <v-icon :color="'white'">close</v-icon>
      </v-btn>
    </div>
    <div class="title-bar">
      <h1 v-if="isNew">Create a New Tag</h1>
      <h1 v-else>Edit Tag</h1>
    </div>
    <v-form class="tag-inputs-container" ref="inputform">
      <br>
      <v-text-field
        class="padded-input"
        :color="colors.PRIMARY"
        :label="'Tag Name'"
        v-model="tmpModel.name"
        :rules="[v => !!v || 'Tag Name is required.']"
      ></v-text-field>
      <v-btn
        v-if="isNew && !isSaving"
        class="white--text create-button"
        :color="colors.PRIMARY"
        v-on:click="createTag"
      >Create</v-btn>
      <v-btn
        v-else-if="!isSaving"
        class="white--text create-button"
        :color="colors.PRIMARY"
        v-on:click="updateTag"
      >Save</v-btn>
      <v-progress-circular
        class="loading-indicator"
        v-if="isSaving"
        indeterminate
        :color="colors.PRIMARY"
      ></v-progress-circular>
    </v-form>
  </div>
</template>

<script>
import colors from "@/colors";
import tagDataAccess from "@/dataAccess/tagDataAccess";

export default {
  name: "edittag",
  data() {
    return {
      colors,
      tmpModel: {},
      isSaving: false,
      tagDAO: new tagDataAccess()
    };
  },
  props: ["value", "isNew"],
  computed: {
    formIsValid() {
      return this.$refs.inputform.validate();
    }
  },
  methods: {
    async createTag() {
      if (!this.formIsValid) {
        return;
      }
      this.isSaving = true;

      try {
        await this.tagDAO.postTag(this.tmpModel);
      } catch (err) {
        this.$emit("saveError", err.response.data);
        this.isSaving = false;
        return;
      }

      this.$emit("closeDialog");
      this.isSaving = false;
    },
    async updateTag() {
      if (!this.formIsValid) {
        return;
      }
      this.isSaving = true;

      try {
        await this.tagDAO.putTag(this.tmpModel);        
      } catch (err) {
        this.$emit("saveError", err.response.data);
        this.isSaving = false;
        return;
      }

      this.$emit("closeDialog");
      this.isSaving = false;
    },
    initForNewModel() {
      this.tmpModel = Object.assign({}, this.value);
      this.$refs.inputform.resetValidation();
    }
  },
  mounted: async function() {
    this.initForNewModel();
  },
  watch: {
    value: {
      handler: function() {
        this.initForNewModel();
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.add-tag-container {
  max-width: 600px;
  box-shadow: 0px 1px 5px #aaaaaa;
  background-color: #fefefe;
}

.dialog-exit-bar {
  height: 80px;
}

.exit-dialog-button {
  margin-top: 10px;
}

.tag-inputs-container {
  margin: 20px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.padded-input {
  margin: 10px 0;
}

.create-button {
  margin-bottom: 25px;
}

.loading-indicator {
  margin-top: 5px;
  margin-bottom: 30px;
}

@media screen and (max-width: 900px) {
  .add-tag-container {
    width: 100%;
    height: 100%;
    max-width: inherit;
  }
}

@media screen and (min-width: 900px) {
  .dialog-exit-bar {
    display: none;
  }
}
</style>