<template>
  <div class="tag-list-container">
    <v-card class="title-bar search-title-bar">
      <h1 class="search-title">Tags</h1>
      <div class="search-input-wrapper">
        <v-autocomplete
          class="search-input"
          :placeholder="'Search'"
          :no-data-text="'No tags found'"
          :color="colors.PRIMARY"
          :items="tagList"
          :item-text="'name'"
          :loading="isLoadingTags"
          v-model="searchSelection"
          v-on:change="displaySearchedTag()"
        ></v-autocomplete>
      </div>
      <v-btn v-on:click="displayNewTag()" :color="colors.PRIMARY" absolute bottom right fab>
        <v-icon :color="'white'">add</v-icon>
      </v-btn>
    </v-card>
    <div margin-top="100px">
      <v-list class="tags-list">
        <v-list-item
          class="padded-tile"
          v-for="tag in tagList"
          :key="tag.Id"
          @click="displayTag(tag)"
        >
          <v-list-item-title v-text="tag.name" class="text-left"></v-list-item-title>
          <v-icon :color="colors.PRIMARY">edit</v-icon>
        </v-list-item>
      </v-list>
    </div>
    <v-dialog
      v-model="addTagShown"
      lazy
      :transition="isMobileDialog ? 'dialog-bottom-transition' : ''"
      :fullscreen="isMobileDialog"
      max-width="600px"
    >
      <EditTag
        v-model="currentSelectedTag"
        :isNew="dialogIsNewTag"
        v-on:closeDialog="addTagShown = false"
        v-on:saveError="displayError"
      />
    </v-dialog>
    <v-snackbar v-model="snackbarShown" :timeout="6000" :color="'red'">{{snackbarMessage}}</v-snackbar>
  </div>
</template>

<script>
import colors from "@/colors";
import EditTag from "./EditTag.vue";
import _ from "lodash";
import tagDataAccess from "@/dataAccess/tagDataAccess";

export default {
  name: "taglist",
  components: {
    EditTag
  },
  data() {
    return {
      colors,
      addTagShown: false,
      isLoadingTags: true,
      currentSelectedTag: {
        id: 0,
        name: ""
      },
      dialogIsNewTag: false,
      searchSelection: "",
      snackbarShown: false,
      snackbarMessage: "",
      tagList: [],
      tagDAO: new tagDataAccess()
    };
  },
  computed: {
    isMobileDialog() {
      return window.innerWidth < 900;
    }
  },
  mounted: async function(){
    await this.initTagList();
    this.isLoadingTags = false;
  },
  methods: {
    async initTagList() {
      try {
        this.tagList = await this.tagDAO.getTags();
      } catch (err) {
        console.log(err);
        return;
      }
    },
    displayNewTag() {
      this.dialogIsNewTag = true;
      this.currentSelectedTag = {
        id: 0,
        name: ""
      };
      this.addTagShown = true;
    },
    displaySearchedTag() {
      this.currentSelectedTag = _.find(
        this.tagList,
        tag => tag.name === this.searchSelection
      );
      this.dialogIsNewTag = false;
      this.addTagShown = true;
    },
    displayTag(tag) {
      this.dialogIsNewTag = false;
      this.currentSelectedTag = tag;
      this.addTagShown = true;
    },
    displayError(errorMessage) {
      this.snackbarMessage = "Error Saving: " + errorMessage;
      this.snackbarShown = true;
    },
    clearAddTagDialog() {
      this.addTagShown = false
    }
  }
};
</script>

<style>
.tag-list-container {
  max-width: 600px;
  margin: 20px;
  box-shadow: 0px 1px 5px #aaaaaa;
  background-color: #fefefe;
}

.search-input-wrapper {
  margin-right: 100px;
  margin-left: 25px;
  width: 100%;
}

.search-title {
  margin-top: 12px;
}

.search-title-bar {
  display: flex;
  flex-direction: row;
  align-content: center;
  padding-top: 10px;
  padding-bottom: 5px;
  z-index: 0;
}

.tags-list {
  background-color: transparent !important;
  margin-top: 25px;
}

.padded-tile {
  padding: 2px 15px;
}
</style>
