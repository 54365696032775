<template>
    <div class="skill-cleanup-link">
        <div class="title-bar darker">
          <h2>Skill Cleanup</h2>
        </div>
        <div class="skill-cleanup-link-container">
          <v-btn class="white--text submit-button" :color="colors.PRIMARY" v-on:click="goToSkillCleanup()">Skill Cleanup</v-btn>
          <p>Have multiple skills in the skills list referencing the same skill? Use the skill cleanup tool to combine those skills into one skill.</p>
        </div>
    </div>
</template>

<script>
import colors from "@/colors";

export default {
    name: 'cleanuplink',
    methods: {
        goToSkillCleanup() {
            this.$router.push("/skillcleanup");
        }
    },
    data(){
      return {
        colors
      }
    }
}
</script>

<style>
.skill-cleanup-link {
  color: #444444;
  box-shadow: 0px 1px 5px #aaaaaa;
  max-width: 400px;
  margin: auto 20px;
  margin-top: 20px;
  flex-direction: column;
  flex: 1 1 200px;
  text-align: left;
  font-size: 16px;
  padding-bottom: 10px;
}

.skill-cleanup-link-container {
  margin: 20px;
  margin-bottom: 0;
}

.submit-button {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  margin-bottom: 25px;
}
</style>