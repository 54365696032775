import apiWrapper from "@/apiWrapper";
import store from "@/store";

class tagDataAccess {
    static cacheTimeoutMilliseconds = 1000 * 60 * 5;
    static lastGetTagsUpdate = -1;

    async getTags(){
        if(this.needsGetTagsRefresh()){
            let response = await apiWrapper.getTags();
            store.commit("updateTags", response.data);

            tagDataAccess.lastGetTagsUpdate = new Date().getTime();
        }
        return store.getters.tagList;
    }

    async postTag(tag){
        let response = await apiWrapper.postTag(tag);
        store.commit("addTag", response.data);
        return store.getters.tag(response.data);
    }

    async putTag(tag){
        let response = await apiWrapper.putTag(tag);
        store.commit("updateTag", response.data);
        store.commit("resolveSkillTags", response.data);
        return store.getters.tag(response.data);
    }

    needsGetTagsRefresh(){
        return tagDataAccess.lastGetTagsUpdate === -1 || 
               new Date().getTime() - tagDataAccess.lastGetTagsUpdate >= tagDataAccess.cacheTimeoutMilliseconds;
    }
}

export default tagDataAccess;