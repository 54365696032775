<template>
  <div class="manageskills">
    <div class="title-bar">
      <h1>Manage Skills</h1>
    </div>
    <div class="manage-skills-content">
      <SkillList class="flex-child"/>
      <TagList class="flex-child"/>
      <Cleanup class="flex-child"/>
    </div>
    <div class="lists-container"></div>
  </div>
</template>

<script>
import SkillList from "./components/SkillList.vue";
import TagList from "./components/TagList.vue";
import Cleanup from "./components/Cleanup.vue";

export default {
  name: "manageskills",
  components: {
    SkillList,
    TagList,
    Cleanup
  }
};
</script>

<style scoped>
.manage-skills-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-child {
  margin: auto 20px;
  margin-top: 20px;
  flex-direction: column;
  flex: 1 1 350px;
}

@media screen and (max-width: 900px) {
  .flex-child {
    margin: 20px 0;
    max-width: 1000px !important;
    width: 100%;
    flex: 1 1 1000px;
  }
}
</style>
