<template>
  <div class="slider-info-container">
    <div class="label">
      <div class="skill-info-container" v-if="saveOnChange">
        <div
          class="skill-info skill-title"
          :style="{'color': colors.CONTENT_TEXT}"
        >{{editableProficiency.skillName}}</div>
        <span
          v-if="showReviewedDate"
          class="skill-info skill-date"
          :style="{'color': colors.CONTENT_SECONDARY_TEXT}"          
        >Reviewed: {{proficiency.reviewedDate}}</span>
        <span
          class="skill-info skill-date"
          :style="{'color': colors.CONTENT_SECONDARY_TEXT}"          
        >Modified: {{proficiency.modifiedDate}}</span>
      </div>
    </div>
    
    <div :class="saveOnChange ? 'slider-container' : 'add-skill-slider'">
      <v-slider
        class="skill-selector"
        v-model="editableProficiency.level"
        min="1"
        max="3"
        :color="colors.PRIMARY"
        tick-size="0"
        thumb-size="64"
        :always-dirty="true"
        :readonly="!canEdit"
        :tick-labels="tickLabels"
        v-on:change="saveProficiency"
      >
        <template v-slot:thumb-label="props">
          <div>
            <span>{{ tickDescriptions[props.value - 1] }}</span>
          </div>
        </template>
      </v-slider>
    </div>
    
    <v-menu right v-if="saveOnChange && canEdit">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" class="proficiency-context-menu" :loading="isLoading">
          <v-icon :style="{'color': colors.CONTENT_SECONDARY_TEXT}">more_vert</v-icon>
        </v-btn>
      </template>
      
      <v-list v-if="!isLoading">
        <v-list-item v-on:click="reviewProficiency">
          <v-icon class="action-icon" :color="colors.PRIMARY">assignment_turned_in</v-icon>
          <v-list-item-title>Mark as Reviewed</v-list-item-title>
        </v-list-item>
        <v-list-item v-on:click="deleteProficiency">
          <v-icon class="action-icon" color="red">delete</v-icon>
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>   

    <v-snackbar
      v-model="snackbarShown"
      :timeout="snackbarTimeout"
      :color="snackbarColor"
    >{{snackbarMessage}}</v-snackbar>
  </div>
</template>

<script>
import proficiencyDataAccess from "../../../dataAccess/proficiencyDataAccess";
import colors from "@/colors";

export default {
  name: "proficiencyslider",
  data() {
    return {
      colors,
      tickLabels: [1, 2, 3],
      tickDescriptions: ["Basic", "Good", "Proficient"],
      snackbarMessage: "",
      snackbarShown: false,
      snackbarTimeout: 2000,
      snackbarColor: colors.ERROR_COLOR,
      deleteSnackbarShown: false,
      editableProficiency: this.proficiency,
      proficiencyDAO: new proficiencyDataAccess(),
      isLoading: false,
    };
  },
  props: ["proficiency", "saveOnChange", "canEdit", "showReviewedDate"],
  methods: {
    async saveProficiency() {
      if (!this.saveOnChange) {
        return;
      }
      let response = "";
      try {
        response = await this.proficiencyDAO.putProficiency(
          this.editableProficiency
        );
      } catch (err) {
        this.snackbarMessage = "Error saving skill";
        this.snackbarShown = true;
        return;
      }

      this.editableProficiency = response;
    },
    async deleteProficiency() {
      try {
        this.isLoading = true;
        await this.proficiencyDAO.deleteProficiency(this.editableProficiency);
      } catch (err) {
        this.isLoading = false;
        this.snackbarColor = "red";
        this.snackbarMessage = "Error deleting skill";
        this.snackbarShown = true;
        return;
      }
    },
    async reviewProficiency(){
      await this.proficiencyDAO.patchProficiencyReviewDate({
        id: this.proficiency.id,
        reviewedDate: new Date().toLocaleDateString()
      });

      this.proficiency.reviewedDate = new Date().toLocaleDateString();
    },
    clear() {
      this.editableProficiency.skillName = "";
      this.editableProficiency.level = "";
    }
  }
};
</script>

<style scoped>
.skill-selector {
  margin-left: 45px;
  margin-right: 25px;
  width: 100%;
}

.add-skill-slider {
  width: 100%;
  margin-right: 50px;
}

.date-label {
  font-size: 10pt;
  color: rgba(0, 0, 0, 0.54);
}

.slider-info-container {
  padding-top: 15px;
  padding-bottom: 5px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  margin-left: -10px;
}

.label {
  display: flex;
  align-items: center;
  margin-top: -15px;
}

.action-icon {
  margin-right: 10px;
}

.label-spacer {
  width: 25px;
}

.skill-info-container {
  display: flex;
  flex-direction: column;
  max-width: 125px;
  min-width: 125px;
  margin-top: 2px;
}

.skill-info {
  font-weight: 300;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  text-align: left;
  margin-left: 20px;
}

.skill-info.skill-title {
  font-size: 12pt;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.skill-info.skill-title:hover {
  overflow:visible;
}

.skill-info.skill-date {
  font-size: 8pt;
}

.delete-progress {
  margin-left: 14px;
  margin-right: 12px;
  margin-bottom: 10px;
}

.proficiency-context-menu{
  margin-top: -8px;
}

</style>
